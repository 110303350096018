html, body, #root {
  height: 100%;
}

/*
 * Utilities
 */

.has-padding-right {
  padding-right: 10px;
}

.has-padding-top {
  padding-top: 5px;
}

.subtitle {
  padding-top: 20px;
}

.subtitle:first-child {
  padding-top: 0;
}

.input--short { width: 60px }

/*
 * Component: LoginScreen
 */

.LoginScreen {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.LoginScreen__logo {
  height: 75px;
}

.LoginScreen .container {
  width: 400px;
  text-align: center;
  flex-grow: 0;
}

.LoginScreen .notification {
  width: 100%;
}

.LoginScreen__form .button {
  width: 100%;
}

/*
 * Component: OAuthButton
 */

.OAuthButton {
  position: relative;
}

.OAuthButton::before {
  --badge-size: 14px;
  content: "";
  display: block;
  width: var(--badge-size);
  height: var(--badge-size);
  position: absolute;
  top: calc(var(--badge-size) / -2);
  right: calc(var(--badge-size) / -2);
  border-radius: 50%;
}

.OAuthButton--status-not-connected::before {
  background-color: hsl(348, 100%, 61%);
  border: 3px #fff solid;
}

/*
 * Component: OAuthManager
 */

 .OAuthManager {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 }